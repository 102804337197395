import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _dc3e6fa0 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _e941bada = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _28a4e32a = () => interopDefault(import('../pages/calendar.vue' /* webpackChunkName: "pages/calendar" */))
const _4de96100 = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _70648246 = () => interopDefault(import('../pages/cp/index.vue' /* webpackChunkName: "pages/cp/index" */))
const _14cbe042 = () => interopDefault(import('../pages/donate/index.vue' /* webpackChunkName: "pages/donate/index" */))
const _4b751f7d = () => interopDefault(import('../pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _03663418 = () => interopDefault(import('../pages/film/index.vue' /* webpackChunkName: "pages/film/index" */))
const _1f44b8f6 = () => interopDefault(import('../pages/limits.vue' /* webpackChunkName: "pages/limits" */))
const _f6ec05be = () => interopDefault(import('../pages/persons.vue' /* webpackChunkName: "pages/persons" */))
const _130f482a = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _5e780eb0 = () => interopDefault(import('../pages/random.vue' /* webpackChunkName: "pages/random" */))
const _2d3a9515 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _248c120f = () => interopDefault(import('../pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _5d8167f7 = () => interopDefault(import('../pages/signin_old.vue' /* webpackChunkName: "pages/signin_old" */))
const _5143c2e6 = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _2e843cb2 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _f7f66c70 = () => interopDefault(import('../pages/sync.vue' /* webpackChunkName: "pages/sync" */))
const _f67e08d4 = () => interopDefault(import('../pages/termsofuse.vue' /* webpackChunkName: "pages/termsofuse" */))
const _7064b934 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _fd0e3d16 = () => interopDefault(import('../pages/watch/index.vue' /* webpackChunkName: "pages/watch/index" */))
const _5ed3c469 = () => interopDefault(import('../pages/ways-to-watch.vue' /* webpackChunkName: "pages/ways-to-watch" */))
const _7186dd18 = () => interopDefault(import('../pages/account/favorites.vue' /* webpackChunkName: "pages/account/favorites" */))
const _aed02af0 = () => interopDefault(import('../pages/account/payed.vue' /* webpackChunkName: "pages/account/payed" */))
const _138fafbd = () => interopDefault(import('../pages/account/promocode.vue' /* webpackChunkName: "pages/account/promocode" */))
const _39ed6f1c = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _2fa1036c = () => interopDefault(import('../pages/cp/feedback.vue' /* webpackChunkName: "pages/cp/feedback" */))
const _29848fe4 = () => interopDefault(import('../pages/cp/kino.vue' /* webpackChunkName: "pages/cp/kino" */))
const _3c3081ee = () => interopDefault(import('../pages/cp/persons.vue' /* webpackChunkName: "pages/cp/persons" */))
const _10266302 = () => interopDefault(import('../pages/donate/123.vue' /* webpackChunkName: "pages/donate/123" */))
const _586245b6 = () => interopDefault(import('../pages/favorites/_id.vue' /* webpackChunkName: "pages/favorites/_id" */))
const _1799e6df = () => interopDefault(import('../pages/film/_id/index.vue' /* webpackChunkName: "pages/film/_id/index" */))
const _aedd393a = () => interopDefault(import('../pages/name/_id.vue' /* webpackChunkName: "pages/name/_id" */))
const _8f11f8b0 = () => interopDefault(import('../pages/tag/_id.vue' /* webpackChunkName: "pages/tag/_id" */))
const _794abd1d = () => interopDefault(import('../pages/watch/_id.vue' /* webpackChunkName: "pages/watch/_id" */))
const _6b2080a9 = () => interopDefault(import('../pages/film/_id/_section.vue' /* webpackChunkName: "pages/film/_id/_section" */))
const _9cceba16 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _dc3e6fa0,
    name: "about"
  }, {
    path: "/account",
    component: _e941bada,
    name: "account"
  }, {
    path: "/calendar",
    component: _28a4e32a,
    name: "calendar"
  }, {
    path: "/contacts",
    component: _4de96100,
    name: "contacts"
  }, {
    path: "/cp",
    component: _70648246,
    name: "cp"
  }, {
    path: "/donate",
    component: _14cbe042,
    name: "donate"
  }, {
    path: "/favorites",
    component: _4b751f7d,
    name: "favorites"
  }, {
    path: "/film",
    component: _03663418,
    name: "film"
  }, {
    path: "/limits",
    component: _1f44b8f6,
    name: "limits"
  }, {
    path: "/persons",
    component: _f6ec05be,
    name: "persons"
  }, {
    path: "/privacy",
    component: _130f482a,
    name: "privacy"
  }, {
    path: "/random",
    component: _5e780eb0,
    name: "random"
  }, {
    path: "/search",
    component: _2d3a9515,
    name: "search"
  }, {
    path: "/signin",
    component: _248c120f,
    name: "signin"
  }, {
    path: "/signin_old",
    component: _5d8167f7,
    name: "signin_old"
  }, {
    path: "/success",
    component: _5143c2e6,
    name: "success"
  }, {
    path: "/support",
    component: _2e843cb2,
    name: "support"
  }, {
    path: "/sync",
    component: _f7f66c70,
    name: "sync"
  }, {
    path: "/termsofuse",
    component: _f67e08d4,
    name: "termsofuse"
  }, {
    path: "/unsubscribe",
    component: _7064b934,
    name: "unsubscribe"
  }, {
    path: "/watch",
    component: _fd0e3d16,
    name: "watch"
  }, {
    path: "/ways-to-watch",
    component: _5ed3c469,
    name: "ways-to-watch"
  }, {
    path: "/account/favorites",
    component: _7186dd18,
    name: "account-favorites"
  }, {
    path: "/account/payed",
    component: _aed02af0,
    name: "account-payed"
  }, {
    path: "/account/promocode",
    component: _138fafbd,
    name: "account-promocode"
  }, {
    path: "/account/settings",
    component: _39ed6f1c,
    name: "account-settings"
  }, {
    path: "/cp/feedback",
    component: _2fa1036c,
    name: "cp-feedback"
  }, {
    path: "/cp/kino",
    component: _29848fe4,
    name: "cp-kino"
  }, {
    path: "/cp/persons",
    component: _3c3081ee,
    name: "cp-persons"
  }, {
    path: "/donate/123",
    component: _10266302,
    name: "donate-123"
  }, {
    path: "/favorites/:id",
    component: _586245b6,
    name: "favorites-id"
  }, {
    path: "/film/:id",
    component: _1799e6df,
    name: "film-id"
  }, {
    path: "/name/:id?",
    component: _aedd393a,
    name: "name-id"
  }, {
    path: "/tag/:id?",
    component: _8f11f8b0,
    name: "tag-id"
  }, {
    path: "/watch/:id",
    component: _794abd1d,
    name: "watch-id"
  }, {
    path: "/film/:id/:section",
    component: _6b2080a9,
    name: "film-id-section"
  }, {
    path: "/",
    component: _9cceba16,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
